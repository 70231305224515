import '../stylesheets/common.scss';

// JavaScriptでマウスオーバーとタッチイベントを扱う
document.addEventListener('DOMContentLoaded', function() {
  const productsLink = document.querySelector("p.products-menu");
  const submenu = document.querySelector('#products-submenu');
  let timeoutId;

  // マウスオーバー時の処理
  productsLink.addEventListener('mouseover', function() {
    clearTimeout(timeoutId); // タイムアウトをクリア
    openSubMenu();
  });

  // マウスが外れた時の処理
  productsLink.addEventListener('mouseleave', function() {
    timeoutId = setTimeout(closeSubMenu, 500); // 1秒後にサブメニューを閉じる
  });

  // サブメニュー上にマウスオーバーした時の処理
  submenu.addEventListener('mouseover', function() {
    clearTimeout(timeoutId); // タイムアウトをクリア
  });

  // サブメニューからマウスが離れた時の処理
  submenu.addEventListener('mouseleave', function() {
    timeoutId = setTimeout(closeSubMenu, 500); // 1秒後にサブメニューを閉じる
  });

  // タッチ時の処理（スマートフォンなど）
  productsLink.addEventListener('click', function(event) {
    event.preventDefault(); // リンクのデフォルト動作を無効化
    if (submenu.style.visibility === "visible") {
      closeSubMenu();
    } else {
      openSubMenu();
    }
  });

  function openSubMenu() {
    submenu.style.visibility = 'visible';
    submenu.style.opacity = 1;
  }

  function closeSubMenu() {
    submenu.style.visibility = "hidden";
    submenu.style.opacity = 0;
  }
});
